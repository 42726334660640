import Vue from 'vue';

import {
  dinamicYieldApiSelectors,
  dinamicYieldBannerImage
} from '@/services/dynamicYield/campaigns';
import Gtm from '@/utils/gtm';

export default () => {
  try {
    sendGTMEventImpression();
  } catch (error) {
    console.error(error);
  }
};

export function sendGTMEventImpression() {
  try {
    const abTests = JSON.parse(window.localStorage.getItem('abTestsStorage'));
    if (!abTests) return;

    const { variantSelectorsPlans, variantHeroSectionBannerImage } = abTests;

    Gtm.sendDynamicYieldEvent(
      variantSelectorsPlans,
      dinamicYieldApiSelectors.selectorsPlans
    );

    Gtm.sendDynamicYieldEvent(
      variantHeroSectionBannerImage,
      dinamicYieldBannerImage.heroSectionBannerImage
    );
  } catch (error) {
    Vue?.prototype?.$honeybadger?.notify(error);
  }
}
